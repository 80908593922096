export const Products = (props) => {
  return (
    <div id='products' className='text-center'>
      <div className='container'>
        <div className='row'>
          <div className="col-md-4">
            <img src="img/chanpin_2_1.webp" className="img-responsive service-img" alt="p1"/>
          </div>
          <div className="col-md-4">
            <img src="img/chanpin_2_2.webp" className="img-responsive service-img" alt="p2"/>
          </div>
          <div className="col-md-4">
            <img src="img/chanpin_2_3.webp" className="img-responsive service-img" alt="p3"/>
          </div>
        </div>
      </div>
    </div>
  )
}
