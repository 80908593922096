export const About = (props) => {
  return (
    <div id='about'>
      <div className='container'>
        <div className='column'>
          <div className='col-md-4'>
            <img src='img/guanyu_logo.webp?v=1' className='img-responsive logo' alt='' />
          </div>
          <div className='col-md-4'>
            <img src='img/guanyu_lianxifangshi.webp' className='img-responsive contact' alt='' />
          </div>
          <img src="img/guanyu_tu_1.webp" className="img-responsive right_img" alt="guanyu" />
          <img src="img/guanyu_xingzhuang.webp" className="img-responsive xingzhuang" alt="guanyu" />
        </div>
      </div>
    </div>
  )
}
