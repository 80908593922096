export const Header = (props) => {
  return (
    <header id='header'>
      <div className='intro'>
        <div className='overlay'>
          <img src="img/home_1_tu.webp?v=1" alt="home" className="img-responsive homebg" />
          <div className='container' >
          </div>
        </div>
      </div>
    </header>
  )
}
