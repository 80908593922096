import { useState, useEffect } from 'react'
import { Navigation } from './components/Navigation'
import { Header } from './components/Header'
import { About } from './components/About'
import { Services } from './components/Services'
import { Footer } from './components/Footer'
import JsonData from './data/data.json'
import SmoothScroll from 'smooth-scroll'
import { Products } from './components/Products'

export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 1000,
  speedAsDuration: true,
})

const App = () => {
  const [landingPageData, setLandingPageData] = useState({})
  
  useEffect(() => {
    setLandingPageData(JsonData)
  }, [])

  return (
    <div>
      <Navigation />
      <Header data={landingPageData.Header} />
      <Products data={landingPageData.Products}/>
      <Services data={landingPageData.Services} />
      <About data={landingPageData.About} />
      <Footer />
    </div>
  )
}

export default App
