export const Footer = (props) => {

  return (
    <div>
      <div id='footer'>
        <div className='container text-center'>
          <div className="column">
            <div className="col-md-3">
              <span className="text">墨比斯（北京）科技有限公司</span>
            </div>
            <div className="col-md-3 ">
              <span className="text">备案号：<a href='https://beian.miit.gov.cn/' target="_blank" rel="noreferrer">京ICP备2024042231号-3</a></span>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
