export const Services = (props) => {
  return (
    <div id='services'>
      <div className='container'>
        <div className="row">
          <img src="img/fuwu_tu_1.webp" className="img-responsive service-hand" alt="stu"/>
          <img src="img/fuwu__line.webp" className="img-responsive service-line" alt="sline"/>
          <img src="img/fuwu_zi_1.webp" className="img-responsive service-title" alt="szi1"/>
        </div>

        <img src='img/fuwu_bg.webp' className="img-responsive service-bg" alt="sbg"/>
        
        <div className="row">
          <img src="img/fuwu_zi_2.webp?v=1" className="img-responsive service-title2" alt="zi2" />
          <div className="col-md-4">
            <img src="img/fuwu_01.webp" className="img-responsive service-item" alt="s01" />
          </div>
          <div className="col-md-4">
            <img src="img/fuwu_02.webp" className="img-responsive service-item" alt="s02"/>
          </div>
          <div className="col-md-4">
            <img src="img/fuwu_03.webp" className="img-responsive service-item" alt="s03"/>
          </div>
        </div>
      </div>
    </div>
  )
}
